var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"},[_c('md-card',[_c('md-card-header',{staticClass:"card-header",attrs:{"data-background-color":"blue"}},[_c('div',{staticClass:"card-header-info"},[_c('h4',{staticClass:"title"},[_vm._v("Добавить пользователя")])])]),_c('ValidationObserver',{ref:"addUserForm",attrs:{"slim":true}},[_c('md-card-content',[_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Никнейм")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.user.nickname),callback:function ($$v) {_vm.$set(_vm.user, "nickname", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.nickname"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("ФИО")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.name"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("E-mail")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.email"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Телефон")]),_c('md-input',{directives:[{name:"mask",rawName:"v-mask",value:('+# (###) ###-##-##'),expression:"'+# (###) ###-##-##'"}],attrs:{"md-counter":"false","maxlength":"18"},on:{"focus":reset},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required|min:8|max:20","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length },attrs:{"md-toggle-password":true}},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Пароль")]),_c('md-input',{attrs:{"type":"password"},on:{"focus":reset},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.password"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length },attrs:{"md-toggle-password":true}},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Повторите пароль")]),_c('md-input',{attrs:{"type":"password"},on:{"focus":reset},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.password_confirmation"}})],1)]}}])}),_c('div',{staticClass:"buttons-wrap"},[_c('md-button',{staticClass:"md-raised md-success",on:{"click":_vm.addNewUser}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }